const getToken = (paramName = "access_token_prev") => {
  if (localStorage) {
    return localStorage.getItem(paramName)
  }
  return null;
};

const removeToken = () => {
  if (localStorage) {
    localStorage.removeItem("access_token_prev");
    localStorage.removeItem("access_token_prev");
  } else {
    console.error("localStorage error");
  }
};

export { getToken, removeToken };
